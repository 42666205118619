import React from 'react';
import { Outlet } from "react-router-dom"
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from './context/AuthProvider'
import { getDataFromApi, getFlorenceMessageFromApi, getFlorencePhotoFromApi, getFoodDataFromApi } from './api'
import { format, isToday } from "date-fns"

const PortalContext = React.createContext()

export default function AuthenticatedUser() {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  //const [userData, setUserData] = React.useState(null);
  const [family, setFamily] = React.useState(null)
  const [health, setHealth] = React.useState({})
  const [healthHistory, setHealthHistory] = React.useState({})
  const [eating, setEating] = React.useState(null)
  const [exercise, setExercise] = React.useState(null)

  //const [exerciseWeekLoading, setExerciseWeekLoading] = React.useState(false)
  //const [exerciseWeek, setExerciseWeek] = React.useState([])

  //const [mealsTodayLoading, setMealsTodayLoading] = React.useState(false)
  //const [mealsToday, setMealsToday] = React.useState([])

  const [exerciseHistoryLoading, setExerciseHistoryLoading] = React.useState(false)
  const [exerciseHistory, setExerciseHistory] = React.useState([])
  
  const [mealsHistoryLoading, setMealsHistoryLoading] = React.useState(false)
  const [mealsHistory, setMealsHistory] = React.useState([])
  
  const [highlightedMeal, setHighlightedMeal] = React.useState()
  const [highlightedExercise, setHighlightedExercise] = React.useState()

  const [owner, setOwner] = React.useState(false)

  const [reload, setReload] = React.useState(false)

  const [sequenceLength, setSequenceLength] = React.useState({length: 0, today: false})
  const [evolutionBar, setEvolutionBar] = React.useState(null)
  const [league, setLeague] = React.useState(null)
  
  const [greeting, setGreeting] = React.useState({message: null, date: null})
  const [messageImage, setMessageImage] = React.useState({id: null, image: null})
  const [exerciseMessageImage, setExerciseMessageImage] = React.useState({id: null, image: null})

  const [savedMessages, setSavedMessages] = React.useState([])
  const [savedMessagePage, setSavedMessagePage] = React.useState(0)
  const [loadingSavedMessages, setLoadingSavedMessages] = React.useState(true)
  const [hasMoreSavedMessages, setHasMoreSavedMessages] = React.useState(true)
  
  const [userPhotos, setUserPhotos] = React.useState([])
  const [userPhotosPage, setUserPhotosPage] = React.useState(0)
  const [loadingUserPhotos, setLoadingUserPhotos] = React.useState(true)
  const [hasMoreUserPhotos, setHasMoreUserPhotos] = React.useState(true)

  const location = useLocation()
  const navigate = useNavigate()

  async function loadDataFromAPI() {
    console.log('load data from api - family')
    if (!auth.refreshToken && !cookies.get("refreshToken")) {
      navigate('./loginrequired')
    } else {
      try {
        //const data = await getDataFromApi('myprofile', auth, cookies)
        const data = await getDataFromApi('family', auth, cookies)
        setFamily(data.family)
        setOwner(data.owner)

        //console.log('Authenticated user: back to the main')

        setAuth(prev => ({...prev, ...data.tokens}))
      } catch (err) {
        console.log(err)
        console.log("Could not authenticate user")
        navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
      }
    }
  }

  async function loadEatingDataFromAPI() {
    try {
      const eatingData = await getDataFromApi(`food/today/${+cookies.get("userId")}`, auth, cookies)

      setEating(eatingData)
      
      if (eatingData && eatingData.ids.length > 0) {
        eatingData.ids.forEach((id) => {
          loadMealHistoryFromAPI(id)
        })
      }
      loadSequenceDataFromApi()

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    } 
  }
  
  async function loadGreetingFromAPI() {
    try {
      const greetingDate = await getDataFromApi(`greeting/${+cookies.get("userId")}`, auth, cookies)

      setGreeting({message: greetingDate.message, date: greetingDate.date})
    } catch (err) {
      console.log('error trying to update greeting')
      console.log(err)
    }
  }
  
  async function loadExerciseDataFromAPI() {
    try {
      const exerciseData = await getDataFromApi(`exercise/info/${+cookies.get("userId")}`, auth, cookies)

      setExercise(exerciseData)

      if (exerciseData && exerciseData.history.length > 0) {
        exerciseData.ids.forEach((id) => {
          loadExerciseHistoryFromAPI(id)
        })
      }
      loadSequenceDataFromApi()

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  async function loadHealthDataFromAPI(id) {
    try {
      const healthData = await getDataFromApi('userhealth', auth, cookies, {"id": id})
      //console.log(healthData)
      setHealth(prev => ({...prev, [id]: healthData}))

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }
  
  async function loadHealthHistoryDataFromAPI() {
    console.log('p2')
    try {
      const healthHistoryData = await getDataFromApi(`health/${+cookies.get("userId")}`, auth, cookies)

      setHealthHistory(healthHistoryData)
      setAuth(prev => ({...prev, ...healthHistoryData.tokens}))

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  

  async function loadSequenceDataFromApi() {

    const result = await getDataFromApi(`sequence/${+cookies.get("userId")}`, auth, cookies)

    setSequenceLength(result.sequence_length)
    setEvolutionBar(result.evolution_bar)
    setLeague(result.league)
  } 

  async function loadMealHistoryFromAPI(id) {

    const foodData = await getFoodDataFromApi(`food/${id}`, auth, cookies)

    //const updatedTodaysFood = mealsToday.filter(food => food.id !== id)
    //updatedTodaysFood.push(foodData.food)
    setMealsHistory(prev => [...prev, foodData.food])
  }
  
  async function loadExerciseHistoryFromAPI(id) {

    const exerciseData = await getFoodDataFromApi(`exercise/${id}`, auth, cookies)

    //const updatedTodaysFood = mealsToday.filter(food => food.id !== id)
    //updatedTodaysFood.push(foodData.food)
    setExerciseHistory(prev => [...prev, exerciseData.food])
  }
  
  //React.useEffect(() => {
  //
  //  console.log('get request family - authenticated user')
  //  loadDataFromAPI()
  //
  //}, [reload])

  React.useEffect(() => {
    console.log('load data from api - family - 0 ')

    if (!auth.refreshToken && !cookies.get("refreshToken")) {
      navigate('./loginrequired')
    } else {
      if (!cookies.get('shared')) {
        loadDataFromAPI()
      }
    }

  }, [])

  const calculateSequenceLength = () => {

    let sequenceLengthProvisory = 0

    let historyItems = []

    const exerciseHistoryFiltered = exercise.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
    const mealsHistoryFiltered = eating.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)

    historyItems = historyItems.concat(mealsHistoryFiltered)
    historyItems = historyItems.concat(exerciseHistoryFiltered)

    let todayPresent = false

    const itemsDate = historyItems.map(item => {
      const dateObject = new Date(item.date)
      if (isToday(dateObject)) {
        todayPresent = true
      }
      return format(dateObject, "dd/MM/yyyy") 
    })

    const datesSet = new Set(itemsDate)

    let sequenceContinues = true
    let currentDay = new Date()
    currentDay.setDate(currentDay.getDate() - 1) // start counting yesterday

    while (sequenceContinues) {
      const currentDayFormatted = format(currentDay, "dd/MM/yyyy")
      if (!datesSet.has(currentDayFormatted)) {
        sequenceContinues = false
      } else {
        currentDay.setDate(currentDay.getDate() - 1)
        sequenceLengthProvisory += 1
      }
    }

    if (todayPresent) {
      sequenceLengthProvisory += 1
    }

    setSequenceLength(prev => ({today: todayPresent, length: sequenceLengthProvisory}))

  }

  React.useEffect(() => {
    //loadSequenceDataFromApi()
    if (eating && eating?.history && exercise && exercise?.history) {
      calculateSequenceLength()
    }
  }, [eating, exercise])

  async function getImageFromBackend(image_id, messageType='eating') {

    if (!image_id) {
      if (messageType === 'eating') {
        setMessageImage({ id: null, image: null })
        return
      } else {
        setExerciseMessageImage({ id: null, image: null })
        return
      }
    }

    const response = await getDataFromApi(`message/image/${image_id}`, auth, cookies, null, true)

    const responseMessageId = await JSON.parse(response.res.headers.get('X-post'))

    //console.log('response message id:', image_id)
    //console.log('message type:', messageType)
    //console.log(responseMessageId)

    if (!responseMessageId.id) {
      if (messageType === 'eating') {
        setMessageImage({ id: image_id, image: null })
        return
      } else {
        setExerciseMessageImage({ id: image_id, image: null })
        return
      }
    }

    const responseMessageImage = await response.res.blob()
    
    if (responseMessageImage.size > 10) {
      if (messageType === 'eating') {
        setMessageImage({ id: image_id, image: responseMessageImage })
      } else {
        setExerciseMessageImage({ id: image_id, image: responseMessageImage })
      }
    }
  }

  React.useEffect(() => {

    // check if eating.message_url is not null
    if (eating && messageImage) {
      if (eating.message_url !== null) {
        // check if eating.message_id is the same as messageImage.id
        if (eating.message_id !== messageImage.id) {
          // refresh messageImage
          //console.log('refreshing message image')

          getImageFromBackend(eating.message_id)
        }
      }
    }
  }, [eating])
  
  React.useEffect(() => {

    // check if eating.message_url is not null
    if (exercise && exerciseMessageImage) {
      if (exercise.message_url !== null) {
        // check if eating.message_id is the same as messageImage.id
        if (exercise.message_id !== messageImage.id) {
          // refresh messageImage
          //console.log('refreshing message image')

          getImageFromBackend(exercise.message_id, "exercise")
        }
      }
    }
  }, [exercise])

  async function loadSavedMessages(page) {
    console.log('ask to get saved messages page', page)
    try {
      setLoadingSavedMessages(true)

      if (page === undefined) {
        return
      }
      
      const requestBody = {
        id: +cookies.get("userId"),
        item_id: savedMessagePage
      }

      const messageData = await getFlorenceMessageFromApi(requestBody, auth, cookies)

      if (messageData.tokens.accessToken) {
        setAuth(prev => ({...prev, ...messageData.tokens}))
      }

      setSavedMessages(prev => {
        return [...prev, ...messageData.messages]
      })

      setHasMoreSavedMessages(messageData.messages.length > 0)

      setLoadingSavedMessages(false)

    } catch (err) {
      console.log("Error loading saved messages...")
      console.log(err)
    }
  }

  React.useEffect(() => {
    
    loadSavedMessages(savedMessagePage)

  }, [savedMessagePage])
  
  async function loadUserPhotos(page) {

    try {
      setLoadingUserPhotos(true)

      if (page === undefined) {
        return
      }
      
      const requestBody = {
        item_id: userPhotosPage 
      }

      const photoData = await getFlorencePhotoFromApi(requestBody, auth, cookies)

      if (photoData.tokens.accessToken) {
        setAuth(prev => ({...prev, ...photoData.tokens}))
      }

      setUserPhotos(prev => {
        return [...prev, ...photoData.info]
      })

      setHasMoreUserPhotos(photoData.info.length > 0)

      setLoadingUserPhotos(false)

    } catch (err) {
      console.log("Error loading user photos...")
      console.log(err)
    }
  }

  React.useEffect(() => {
    
    loadUserPhotos(userPhotosPage)

  }, [userPhotosPage])

  //React.useEffect(() => {
  // 
  //  console.log('loading meals history')
  //  if (!mealsHistoryLoading) {
  //    setMealsHistoryLoading(true)
  //    if (eating && eating.ids.length > 0) {
  //      eating.ids.forEach((id) => {
  //        loadMealHistoryFromAPI(id)
  //      })
  //    }
  //    setMealsHistoryLoading(false)
  //  }
  //}, [eating])
  
  //React.useEffect(() => {
  //
  //  console.log('loading exercise history')
  //  if (!exerciseHistoryLoading) {
  //    setExerciseHistoryLoading(true)
  //    if (exercise && exercise.history.length > 0) {
  //      exercise.ids.forEach((id) => {
  //        loadExerciseHistoryFromAPI(id)
  //      })
  //    }
  //    setExerciseHistoryLoading(false)
  //  }
  //
  //}, [exercise])

  const setTutorialSeen = () => {
      const updatedUserHealth = health[+cookies.get("userId")]
      updatedUserHealth.tutorial = true
      setHealth(prev => ({...prev, [+cookies.get("userId")]: updatedUserHealth}))
  }

  return (
    <PortalContext.Provider value={{
      sequenceLength, 
      family, 
      owner, 
      setReload, 
      auth, 
      cookies, 
      health,
      setHealth,
      eating,
      exercise,
      setEating,
      setExercise,
      loadHealthDataFromAPI, 
      loadHealthHistoryDataFromAPI,
      healthHistory,
      setHealthHistory,
      loadEatingDataFromAPI, 
      //mealsToday,
      mealsHistory,
      //setMealsToday,
      setMealsHistory,
      //mealsTodayLoading,
      loadExerciseDataFromAPI,
      //exerciseWeek,
      exerciseHistory,
      //setExerciseWeek,
      setExerciseHistory,
      //exerciseWeekLoading,
      highlightedMeal,
      setHighlightedMeal,
      highlightedExercise,
      setHighlightedExercise,
      setTutorialSeen,
      calculateSequenceLength,
      loadDataFromAPI,
      evolutionBar,
      setEvolutionBar,
      league,
      setLeague,
      loadGreetingFromAPI,
      greeting,
      messageImage,
      exerciseMessageImage,
      savedMessages,
      setSavedMessages,
      loadingSavedMessages,
      hasMoreSavedMessages,
      setSavedMessagePage,
      setLoadingSavedMessages,
      setHasMoreSavedMessages,
      loadSavedMessages,
      userPhotos,
      setUserPhotos,
      loadingUserPhotos,
      hasMoreUserPhotos,
      setUserPhotosPage,
      setLoadingUserPhotos,
      setHasMoreUserPhotos,
      loadUserPhotos
    }}>
      <Outlet />
    </PortalContext.Provider>
  )
}

export { PortalContext }



