import React from 'react'
import Button from '../components/Button'
import closeMenuLogo from "../images/x-solid-black.svg"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { format } from 'date-fns'
import { deleteDataFromApi, putRequestToApi } from '../api'
import HealthHistoryTextField from './HealthHistoryTextField'

export default function HealthHistoryPrescriptionTextModal({ modalId, setModalState }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { healthHistory } = React.useContext(PortalContext);
  
  const description = healthHistory[cookies.get("userId")]
    .filter(card=>card.id===modalId.cardId)[0].items
    .filter(item=>item.id===modalId.itemId)[0]

  console.log(description)

  const [folderFormData, setFolderFormData] = React.useState({
    text: description.content
  })

  const [error, setError] = React.useState(null)
  const [status, setStatus] = React.useState('idle')
  const [edit, setEdit] = React.useState(false)

  const userRef = React.useRef()

  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);
  
  const deleteItemFromBackend = async () => {

    await deleteDataFromApi(
      {},
      `/prescription/${modalId.itemId}`, 
      auth, cookies
    )
  }

  const deleteItem = () => {
    deleteItemFromBackend()

    const updatedItems = healthHistory[+cookies.get("userId")]
      .filter(card=>card.id===modalId.cardId)[0].items
      .filter(item=>item.id!==modalId.itemId)

    const updatedFolderWithDeletion = healthHistory[+cookies.get("userId")]
      .filter(card=>card.id===modalId.cardId)[0]
    updatedFolderWithDeletion.items = updatedItems

    setModalState(false)
  }
   
  const handleSubmit = (e) => {
    e.preventDefault()

    if (folderFormData.text ) {

      setError(null)

      async function tryUpdatingDescription(folderFormData) {
        try {
          setStatus("submitting")

          const requestBody = {
            file_name: folderFormData.text
          }

          const data = putRequestToApi(requestBody, `/prescription/${modalId.itemId}`, auth, cookies)  // here call the api

          setAuth(prev => ({...prev, ...data.tokens}))

          // here need to update the right things

          description.content = folderFormData.text
    
          const updatedItems = healthHistory[+cookies.get("userId")]
            .filter(card=>card.id===modalId.cardId)[0].items
            .filter(item=>item.id!==modalId.itemId)

          updatedItems.unshift(description)

          const updatedFolderWithDeletion = healthHistory[+cookies.get("userId")]
            .filter(card=>card.id===modalId.cardId)[0]
          updatedFolderWithDeletion.items = updatedItems


        } catch(err) {
          setError(err.message)
        } finally {         
          setStatus("idle")
          setModalState(false)
        }
      }
      
      tryUpdatingDescription(folderFormData)

    } else {
      setError("O texto da descrição não pode estar vazio")
    }
  }
 
  if (!description) {
    return null
  }

  return (
    <div className="modal-background">
      <div className="modal-container">

        <div className="modal-close-button">
          <button className="svg-btn" onClick={()=>setModalState(false)} buttonType='button'>
            <img className="health-history-small-menu-nav health-history-inv-svg" 
                src={closeMenuLogo} alt="Button to toggle menu" />
          </button>
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <p className="intra-page-title bottom-margin-xs">Prescrição {format(description.date_created, 'dd/MM')}</p>
          <Button type="tertiary modal-teriary-button" onClick={()=>deleteItem()}>Excluir</Button>
        </div>
        
        {(!edit) &&
          <button className="body health-history-description" onClick={()=>setEdit(prev=>!prev)}>
            {description.content}
          </button>
        }

        {(edit) &&
          <HealthHistoryTextField
            error={error}
            handleSubmit={handleSubmit}
            folderFormData={folderFormData}
            setFolderFormData={setFolderFormData}
            status={status}
            setEdit={setEdit} />
        }

      </div>
    </div>
  )
}
