import React from "react"

export default function HealthHistorySubjectMenu({ setSubjectMenu, currentMenu }) {

  return (
    <div className="health-history-menu-subject bottom-padding-xs">

      <button onClick={()=>setSubjectMenu(0)} className="health-history-card-button right-padding-xs" 
        style={{textAlign: "center", fontWeight: (currentMenu===0) ? "bold" : "normal" }}>
        Geral
      </button>
      <button onClick={()=>setSubjectMenu(1)} className="health-history-card-button right-padding-xs" 
        style={{textAlign: "center", fontWeight: (currentMenu===1) ? "bold" : "normal" }}>
        Problemas crônicos 
      </button>
      <button onClick={()=>setSubjectMenu(2)} className="health-history-card-button right-padding-xs" 
        style={{textAlign: "center", fontWeight: (currentMenu===2) ? "bold" : "normal" }}>
        Problemas agudos
      </button>
      <button onClick={()=>setSubjectMenu(-1)} className="health-history-card-button right-padding-xs" 
        style={{textAlign: "center", fontWeight: (currentMenu===-1) ? "bold" : "normal" }}>
        Ver tudo
      </button>
    </div>
  )
}
