import React from 'react'
import closeMenuLogo from "../images/x-solid-black.svg"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi } from '../api'
import Button from './Button'
import Fisherman from '../images/sport-fisherman.svg'
import PaperClip from '../images/paper-clip.svg'
import Attention from "../images/sun.svg"
import HighlightedMessage from './HighlightedMessage'
import HealthHistoryTextField from './HealthHistoryTextField'

export default function HealthHistoryAddFiles({ modalId, setModalState }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setHealthHistory, healthHistory } = React.useContext(PortalContext);
  
  const card = healthHistory[cookies.get("userId")]
    .filter(card=>card.id===modalId.cardId)[0].items

  const [error, setError] = React.useState(null)
  const [status, setStatus] = React.useState('idle')
  const [postImage, setPostImage] = React.useState([])
  const [manualAdd, setManualAdd] = React.useState(false)
  const [folderFormData, setFolderFormData] = React.useState({
    text: ""
  })

  const changeFromFileToManual = () => {
    setManualAdd(prev=>!prev)
    setFolderFormData({text: ""})
    setPostImage([])
    setError(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if ( postImage.length > 0 || folderFormData.text !== "" ) {

      setError(null)

      async function trySubmitingFiles() {
        try {
          setStatus("submitting")

          let requestBody = {
            title: null,
            id: +cookies.get("userId"),  
            folder: [modalId.cardId],
          }

          let submittionUrl = null
          let name = "exam" 
          let data_type = "exam"

          if (modalId.type==="addExam") {
            submittionUrl=`/exam/${+cookies.get("userId")}`
          } else if (modalId.type==="addPrescription") {
            submittionUrl=`/prescription/${+cookies.get("userId")}`
            data_type = "prescription"
            requestBody['title'] = folderFormData.text
          } else if (modalId.type==="addVaccine") {
            submittionUrl=`/vaccine/${+cookies.get("userId")}`
            data_type = "vaccine"
          } else if (modalId.type==="addPhoto") { 
            submittionUrl="/health/photo"
            name="photo"
            data_type = "health_photo"
            requestBody = {
              id: +cookies.get("userId"),  
              folder: [modalId.cardId],
            }
          } else {
            submittionUrl=`/vaccine/${+cookies.get("userId")}`
          }

          const imageFormData = new FormData()
          
          postImage.forEach(item => {
            imageFormData.append(name, item)
          })

          const data = await postRequestToApi(
            imageFormData, 
            submittionUrl, 
            auth, 
            cookies, 
            true, 
            false, 
            JSON.stringify(requestBody)
          )

          setAuth(prev => ({...prev, ...data.tokens}))

          // here need to update the right things

          if (data.id[0].external_filename.length === 0 && data.id[0]?.content) {

            card.unshift({
              date_created: new Date(),
              extension: "text",
              external_filename: [],
              filename: [],
              id: data.id[0].id,
              type: data_type,
              content: data.id[0].content
            })

          } else {

            const newImages = {
              date_created: new Date(),
              extension: "jpeg",
              external_filename: [],
              filename: [],
              id: null,
              type: data_type,
            }

            const newPdfs = {
              date_created: new Date(),
              extension: "pdf",
              external_filename: [],
              filename: [],
              id: null,
              type: data_type,
            }
            
            data.id.forEach((item) => {
              if (item.filename.split('.')[1] === "pdf") {
                newPdfs.external_filename.push(item.external_filename)
                newPdfs.filename.push(item.filename)
                newPdfs.id = item.id
              } else {
                newImages.external_filename.push(item.external_filename)
                newImages.filename.push(item.filename)
                newImages.id = item.id
              }
            })
             
            card.unshift(newPdfs)
            card.unshift(newImages)

          }

          // data.id is an array with objects of form: { id, filename, external_filename }
          //card.push({
          //  date_created: new Date(),
          //  id: data.id,
          //  type: "description",
          //})

        } catch(err) {
          setError(err.message)
        } finally {         
          setStatus("idle")
          setModalState(false)
        }
      }
      
      trySubmitingFiles()

    } else {
      setError("Você ainda não adicionou nenhum arquivo nem adicionou informações manualmente")
    }
  }
 

  function handleImageChange(e) {
    const target = e.target

    setPostImage(prev => [...prev, ...target.files])
  }

  let imageUrls = []
  let pdfFiles = []
  
  //let imageUrl = null
  if (postImage) {
    postImage.map((item, index) => {
      if (item.name.split('.')[1] === "pdf") {
        pdfFiles.push({name: item.name, index: index})
      } else {
        imageUrls.push({blob: window.URL.createObjectURL(item), index: index})
      }
    })
  }

 
  return (
    <div className="modal-background">
      <div className="modal-container">

        <div className="modal-close-button">
          <button className="svg-btn" onClick={()=>setModalState(false)} buttonType='button'>
            <img className="health-history-small-menu-nav health-history-inv-svg" 
                src={closeMenuLogo} alt="Button to toggle menu" />
          </button>
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <p className="intra-page-title bottom-margin-xs">{(manualAdd) ? "Digitar" : "Adicionar arquivos"}</p>
          {modalId.type==="addPrescription" && 
            <Button type="tertiary modal-teriary-button" onClick={()=>changeFromFileToManual()}>{(manualAdd) ? "Adicionar arquivos" : "Digitar"}</Button>}
        </div>

        {(error) ?
        <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null }
    
        {(!manualAdd) ? 
        <form onSubmit={handleSubmit}>
          { (postImage.length === 0) ?
            <div className="submit-column vertical-margin-m  modal-teriary-button">
              <img className="submission-image-small modal-teriary-button" src={Fisherman} alt="No files yet"/>
              <p className="body secondary-color top-margin-s modal-teriary-button">Nenhum arquivo selecionado</p>
            </div> : 
            <div>
              <div style={{ display: "flex", flexDirection: "row", gap: "4px", flexWrap: "wrap" }}>
                {imageUrls.map(item => (
                  <div style={{ display: "inlineBlock" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                      <img className="submit-image-preview-fixed" src={item.blob} alt="Submission image"/>
                      <Button type="tertiary modal-teriary-button" onClick={()=>setPostImage(prev=>prev.filter((i,idx)=>idx!==item.index))}>Excluir</Button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="top-margin-xs">
                {pdfFiles.map(item => (
                  <div style={{display: "flex", alignItems: "center", gap: "0.5rem", height: "1.5rem"}}>
                    <img className={"button-icon-smaller"} src={PaperClip} alt="Document annexed"/>
                    <p type="body modal-teriary-button" key={item.index}>{item.name}</p>
                    <Button type="tertiary modal-teriary-button" onClick={()=>setPostImage(prev=>prev.filter((i,idx)=>idx!==item.index))}>Excluir</Button>
                  </div>
                ))} 
              </div>
            </div>
          }

          <div className="top-margin-s">
            <Button type="primary right-margin-xs">
              {(status==="idle")? "Salvar" : "Salvando..."}
            </Button>
            <Button type="secondary right-margin-s" buttonType="button">
              <label for="upload-photo">Selecionar arquivos</label>
            </Button>
          </div>

          <div>
            <input type="file"
              className="bottom-margin-s"
              id="upload-photo" 
              name="image" 
              accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf" 
              onChange={handleImageChange}
              multiple
              />
          </div>
        </form>
        :
        <HealthHistoryTextField
          error={false}
          handleSubmit={handleSubmit}
          folderFormData={folderFormData}
          setFolderFormData={setFolderFormData}
          status={status}
          setEdit={setModalState} />
        }
      </div>
    </div>
  )
}
