import React from 'react'
import Button from '../components/Button'
import closeMenuLogo from "../images/x-solid-black.svg"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { format } from 'date-fns'
import { deleteDataFromApi, getDataFromApi } from '../api'
import PageScroller from '../components/PageScroller'

export default function HealthHistoryPdfModal({ modalId, setModalState }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setHealthHistory, healthHistory } = React.useContext(PortalContext);
 
  const [ exam, setExam ] = React.useState(null)

  React.useEffect(() => {
    loadExamFromAPI()
  }, [])

  const imgUrl = "/document/internal/pdf"

  let heading = ""
  let deleteUrl = ""
  if (modalId.type === "vaccine") {
    heading = "Vacinas"
    deleteUrl = "/vaccine"
  } else if (modalId.type === "prescription") {
    heading = "Prescrições"
    deleteUrl = "/prescription"
  } else if (modalId.type === "exam") {
    heading = "Exames"
    deleteUrl = "/exam"
  }
  
  async function loadExamFromAPI() {  
    try {
      const data = await getDataFromApi(`${imgUrl}/${modalId.filename.split('.')[0]}`, auth, cookies, null, true) 
      
      const doc = await data.res.blob()

      setExam(doc)

    } catch (err) {
      console.log(err)
      console.log("Could not download document")
    }
  }

  const deleteItemFromBackend = async () => {

    await deleteDataFromApi(
      {name: modalId.filename, id: modalId.cardId}, 
      deleteUrl, auth, cookies
    )
  }

  const deleteItem = () => {
    deleteItemFromBackend(modalId.filename)

    const updatedFilenames = healthHistory[+cookies.get("userId")]
      .filter(card=>card.id===modalId.cardId)[0].items
      .filter(item=>item.id===modalId.itemId)[0].filename
      .filter(name=>name!==modalId.filename)
    const updatedExternalNames = healthHistory[+cookies.get("userId")]
      .filter(card=>card.id===modalId.cardId)[0].items
      .filter(item=>item.id===modalId.itemId)[0].filename
      .filter(name=>name!==modalId.filename)

    const updatedFolderWithDeletion = healthHistory[+cookies.get("userId")]
     .filter(card=>card.id===modalId.cardId)[0].items
     .filter(item=>item.id===modalId.itemId)[0]
    
    updatedFolderWithDeletion.filename = updatedFilenames
    updatedFolderWithDeletion.external_filename = updatedExternalNames

    setModalState(false)
  }
  
  const download = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "document.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url)
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  if (!exam) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando documento...</p> 
      </div>
    )
  }
 
  return (
    <div className="modal-background">
      <div className="modal-container">

        <div className="modal-close-button">
          <button className="svg-btn" onClick={()=>setModalState(false)} buttonType='button'>
            <img className="health-history-small-menu-nav health-history-inv-svg" 
                src={closeMenuLogo} alt="Button to toggle menu" />
          </button>
        </div>

        <p className="intra-page-title bottom-margin-xs">{heading}</p>
        
        <div>

          <PageScroller 
            examTitle={null}
            download={()=>{}}
            allowDownload={false}>
            {exam}
          </PageScroller>

          <div style={{ display: "flex" }} className="bottom-margin-s">
            <a className="btn-tertiary modal-teriary-button right-margin-xs" href={`${imgUrl}/${modalId.filename.split('.')[0]}`} download onClick={e => download(e)} target = "_blank">
                Download
            </a>
            <Button type="tertiary modal-teriary-button" onClick={()=>{deleteItem(modalId.filename)}}>
              Excluir 
            </Button>
          </div>
        </div>

      </div>
    </div>
  )
}
